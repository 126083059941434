.Mui-disabled {
  color: #FFF !important;
  -webkit-text-fill-color: #fff !important;
}
::-webkit-scrollbar {
  width: 1em;
}
 
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}
 
::-webkit-scrollbar-thumb {
  background-color: #8A7B7B;
  outline: 1px solid slategrey;
  border-radius: 10px;
}
 
/* General Reset */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    font-family: "Kode Mono", monospace;
    color: #fff;

    overflow: hidden;
}

header {
    background-color: #010032;
    padding: 10px 20px;
    border-radius: 10px;
}

nav ul {
    display: flex;
    justify-content: space-around;
    list-style: none;
}

nav ul li a {
    color: #fff;
    text-decoration: none;
}

.hero {
    background-color: #333;
    text-align: center;
    padding: 100px 20px;
}

.hero h1 {
    font-size: 3rem;
    color: #00ff00;
}

.hero p {
    font-size: 1.5rem;
    margin-bottom: 20px;
}

.hero .cta {
    background-color: #00ff00;
    color: #111;
    padding: 15px 30px;
    text-decoration: none;
    border-radius: 5px;
}

.tokenomics, .roadmap, .community {
    padding: 60px 20px;
    text-align: center;
}
.about {
    text-align: center;
}
.about h2, .tokenomics h2, .roadmap h2, .community h2 {
    font-size: 2.5rem;
    color: #00ff00;
}

.about p, .tokenomics ul, .roadmap ul, .community p {
    font-size: 1.2rem;
}
#about {
  height: calc(100vh - 61px);
}
.terminal-section {
    display: flex;
    justify-content: center;
    padding: 60px 20px;
}

.terminal {
    background-color: #00003c;
    border-radius: 10px;
    width: 350px;
    overflow: hidden;
    z-index: 1;
}
.chat {
    background-color: #00003c;
    border-radius: 10px;
    text-align: left;
    height: 200px;
    overflow: hidden;
    position: relative;
    min-height: 200px;
    z-index: 1;
}

.terminal-header {
    display: flex;
    justify-content: space-between;
    background-color: #1a1a50;
    padding: 5px 15px;
    border-radius: 10px 10px 0 0;
}

.buttons span {
    display: inline-block;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    margin-right: 3px;
    line-height: 8px;
    color: #000;
}
.buttons {
  white-space: nowrap;
}

.red { background-color: #ff605c; cursor: pointer; }
.yellow { background-color: #ffbd44; cursor: pointer; }
.green { background-color: #00ca4e; cursor: pointer; }

.terminal-title {
    color: #fff;
    font-size: 10px;
    white-space: nowrap;
    line-height: 20px;
}

.terminal-body {
    padding: 20px;
    max-height: 100%;
    overflow-y: auto;
    opacity: 0.7;
    height: 100%;
}
.chat-body {
    padding: 20px;
    max-height: 120px;
    overflow-y: auto;
}
#chat-output {
    font-size: 14px;
}

#chat-input {
    width: 100%;
    padding: 10px;
    background-color: #1a1a50;
    color: #00ff00;
    border: none;
    font-family: 'Courier New', Courier, monospace;
}

footer {
    background-color: #222;
    text-align: center;
    padding: 20px;
    color: #fff;
}
.rectangle-5088-1esLrs {
  background: linear-gradient(180deg,rgb(0, 0, 0),rgba(4.250000221654773,0,255,.5) 80%,rgba(4.250000221654773,0,255,.6));

  background-color: rgba(0, 0, 0, 0);
  background-color: transparent;

  box-shadow: 0px 4px 4px #00000040;
  height: 100vh;
  left: 0px;
  mix-blend-mode: color;
  position: absolute;
  top: 0px;
  width: 100%;
}
.mountains-1624284_1920-1-1esLrs {
  background-color: transparent;
  height: 1975px;
  left: 0px;
  object-fit: cover;
  position: absolute;
  top: 0px;
  width: 3458px;
}
.image_e8uc8-na-l_1730407787747_raw-1-1-1esLrs {
  background-color: transparent;
  height: 205px;
  width: 270px;
  
  margin: auto;
  border: 2.9799723625183105px solid;
  border-top-color: currentcolor;
  border-right-color: currentcolor;
  border-bottom-color: currentcolor;
  border-left-color: currentcolor;
  border-color: #ffeb9e;
  border-radius: 50%;

}
.main {
  display: flex;
  height: 100%;
  flex-direction: column;
  background-color: transparent;
  position: relative;
  transition: all 0.2s;
  justify-content: space-between;
}
.mainimg {
  height: 100%;
  width:100%;
  opacity: 0.1;
  position: absolute;
}
.spacer {
  height: 24px;
}
.buy-hvaldimir{
  background-color: #00ffa5;
  z-index: 1;
  position: absolute;
  color: #020081;
  font-weight: 700;
  font-size: 7px;
  width: 40px;
  text-align: center;
}
.n0OVSv {
  top: 102px;
  left: 88px;
}
.n1OVSv {
  top: 102px;
  left: 142px;
}
.text_label-1esLrs {
  color: #0400ff;
  font-weight: 700;
  opacity: 0.33;
  font-size: 46px;
}
.ellipse-13-EmRrqB {
  background-color: transparent;
  border: 3.1533596515655518px solid;
  border-color: #000;
  border-radius: 364.21px/33.9px;
  filter: blur(15.766796112060547px);;
  height: 23px;
  left: 50%;
  opacity: 0.78;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 252px;
}

.ellipse-20-EmRrqB {
  background-color: transparent;
  border: 2.3650197982788086px solid;
  border-color: #fff;
  border-radius: 364.21px/33.9px;
  height: 23px;
  left: 50%;
  opacity: 0.71;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 252px;
}

.ellipse-14-EmRrqB {
  background-color: transparent;
  border: 3.1533596515655518px solid;
  border-color: #000;
  border-radius: 737.1px/68.59px;
  filter: blur(23.650197982788086px);;
  height: 47px;
  left: 50%;
  opacity: 0.73;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 511px;
}

.ellipse-19-EmRrqB {
  background-color: transparent;
  border: 3.1533596515655518px solid;
  border-color: #fff;
  border-radius: 737.1px/68.59px;
  height: 47px;
  left: 50%;
  opacity: 0.37;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 511px;
}
.group-14-EmRrqB {
  background-color: transparent;
  height: 62px;
  left: 50%;
  position: absolute;
  top: 25%;
  transform: translate(-50%, -50%);
  width: 69px;
}

.vector-4-EmRrqB {
  background-color: transparent;
  height: 36px;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 26px;
}

.vector-3-EmRrqB {
  background-color: transparent;
  height: 62px;
  left: 50%;
  position: absolute;
  top: 25%;
  transform: translate(-50%, -50%);
  width: 70px;
}

.ellipse-16-EmRrqB {
  background-color: #000;
  border-radius: 1.97px/4.73px;
  height: 3px;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  transform: rotate(-34.50deg);
  width: 1px;
}
.ellipse-17-EmRrqB {
  background-color: #fff;
  border-radius: 5.52px/8.61px;
  filter: blur(3.1533596515655518px);;
  height: 6px;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  transform: rotate(-30.46deg);
  width: 3px;
}
.ellipse-18-EmRrqB {
  background-color: #fff;
  border-radius: 5.01px/4.93px;
  filter: blur(3.1533596515655518px);;
  height: 3px;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  transform: rotate(-27.96deg);
  width: 3px;
}
.ellipse-15-EmRrqB {
  background-color: transparent;
  border: 1.2px solid;
  border-color: #ffeb9e;
  border-radius: 81.2px/7.56px;
  height: 5px;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  top: -25%;
  width: 56px;
}
.group-15-EmRrqB {
  background-color: transparent;
  height: 38px;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0.3;
  position: absolute;
  top: 75%;
  width: 82px;
}

.ellipse-10-EX63O5 {
  background-color: #5c5c5c;
  border-radius: 106.68px/20.13px;
  height: 14px;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  top: 12px;
  width: 74px;
}

.ellipse-9-EX63O5 {
  background-color: #474747;
  border-radius: 117.95px/29.39px;
  height: 20px;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  top: 0px;
  width: 82px;
}

.ellipse-11-EX63O5 {
  background-color: #5c5c5c;
  border-radius: 77.7px/22.95px;
  height: 16px;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  top: 17px;
  width: 53px;
}

.ellipse-12-EX63O5 {
  background-color: #5c5c5c;
  border-radius: 57.55px/17.34px;
  height: 12px;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  top: 26px;
  width: 40px;
}
@keyframes blink {50% { color: transparent }}
.loader__dot { animation: 1s blink infinite }
.loader__dot:nth-child(2) { animation-delay: 250ms }
.loader__dot:nth-child(3) { animation-delay: 500ms }


